import * as React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mobileImg from "../images/mobile.png";
import heroImg1 from "../images/hero-1.png";
import heroImg2 from "../images/hero-2.png";
import heroImg3 from "../images/hero-3.png";
import desktopImg from "../images/desktop.png";
import Layout from "../components/Layout";
import SEO from "../components/seo";

const IndexPage = () => {
  return (
    <Layout>
      <SEO />
      <title>덴트링크</title>
      <HeroSection>
        <div css={ColumnCenter}>
          <h3>
            기공물 수거 요청
            <br />
            클릭 한번으로 끝!
          </h3>
          <p>
            전화, 카카오톡으로 이뤄진 기공물 수거 요청을{" "}
            <strong>웹 또는 모바일</strong>로 <strong>클릭 한 번에</strong>{" "}
            끝내세요.
          </p>
        </div>
      </HeroSection>
      <HeroSection color="#3751ff" css={css``}>
        <div
          css={css`
            position: relative;
            height: 550px;
            @media screen and (max-width: 1023px) {
              height: 400px;
            }
            padding: 0;
          `}
        >
          <img
            src={desktopImg}
            alt="덴트링크 데스크탑"
            css={css`
              position: absolute;
              bottom: 0px;
              width: 1024px;
              @media screen and (max-width: 1023px) {
                width: 768px;
                left: calc(50% - 384px);
              }
              @media screen and (max-width: 767px) {
                display: none;
              }
            `}
          />
          <img
            src={mobileImg}
            css={css`
              position: absolute;
              bottom: 0px;
              width: 600px;
              display: none;
              left: calc(50% - 300px);
              @media screen and (max-width: 767px) {
                display: block;
              }
            `}
          />
        </div>
      </HeroSection>
      <HeroSection>
        <div css={FlexBetween}>
          <div css={ColumnLeft}>
            <h5>
              거래 기공소에 클릭 한번으로
              <br />
              수거요청을 끝내세요
            </h5>
            <p>
              전화, 카카오톡으로 이뤄진 수거 요청을
              <br />
              클릭 한번에 끝내세요.
            </p>
          </div>
          <img src={heroImg1} alt="핸드폰을 가리키는 사진" css={ImgSize} />
        </div>
      </HeroSection>
      <HeroSection
        css={css`
          background-color: #fbfbfb;
          height: 338px;
        `}
      >
        <div css={ColumnCenter}>
          <p
            css={css`
              font-size: 32px !important;
              line-height: 48px;
            `}
          >
            Dentlink는{" "}
            <strong
              css={css`
                color: #3751ff;
              `}
            >
              치과의사, 위생사, 기공사
            </strong>
            <br />
            <strong>모두를 위한 편리함</strong>을 추구합니다.
          </p>
        </div>
      </HeroSection>
      <HeroSection>
        <div css={FlexBetween}>
          <img src={heroImg2} css={ImgSize} alt="종이에 사인하는 그림" />
          <div css={ColumnRight}>
            <h5>치과의 수거요청을 한 곳에서 받으세요.</h5>
            <p>전화나 카톡보다 편리한 수거요청 접수</p>
          </div>
        </div>
      </HeroSection>
      <HeroSection color="#fbfbfb">
        <div css={FlexBetween}>
          <div css={ColumnLeft}>
            <h5>편리한 전자 기공물 장부</h5>
            <p>기공물 장부를 Dentlink에서 편리하게 관리하세요.</p>
          </div>
          <img src={heroImg3} css={ImgSize} alt="종이에 사인하는 그림" />
        </div>
      </HeroSection>
    </Layout>
  );
};

export default IndexPage;

const HeroSection = styled.section`
  overflow: hidden;
  background-color: #fff;
  ${(props) =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
  width: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    width: 1024px;
    max-width: 100vw;
    padding: 30px 40px;

    @media screen and (max-width: 678px) {
      display: flex;
      flex-direction: column;
      padding: 30px 20px;
    }
  }

  word-break: keep-all;
  h3 {
    font-size: 48px;
    @media screen and (max-width: 375px) {
      font-size: 36px;
    }
  }
  h5 {
    font-size: 32px;
  }
  p {
    font-size: 24px;
    font-weight: 300;
    strong {
      font-weight: 500;
    }
  }
`;

const ColumnCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  gap: 20px;
`;

const ColumnRight = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  text-align: right;
  gap: 20px;
`;
const ColumnLeft = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  gap: 20px;
`;

const FlexBetween = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const ImgSize = css`
  width: 340px;
  max-width: 100vw;
  height: 300px;

  @media screen and (max-width: 375px) {
    width: 260px;
    height: 240px;
  }
`;
